import request from '@/utils/request'

export function pageInit(id) {
    return request({
        url:'/api/ad/product/pageInit',
        method:'get',
        headers:{
            'profileId':id
        }
    });
}

export function listPage(params) {
    return request({
        url:'/api/ad/product/listPage',
        method:'get',
        params
    });
}

// 关注与取消关注
export function focusOn(id) {
    return request({
        url:'/api/cpc/focus/add',
        method:'get',
        params:{
            productId:id
        }
    });
}
export function focusOff(id) {
    return request({
        url:'/api/cpc/focus/cancel',
        method:'get',
        params:{
            productId:id
        }
    });
}

export function batchUpdate(data,id) {
    return request({
        url:'/api/ad/product/batchUpdate',
        method:'put',
        data,
        headers:{
            'profileId':id
        }
    });
}

export function addInit(id) {
    return request({
        url:'/api/ad/product/addInit',
        method:'get',
        headers:{
            'profileId':id
        }
    });
}

// 获取商品
export function getGoods(params) {
    return request({
        method:'get',
        url:'/api/amazon/asin/stockListPage',
        params
    })
}

// 根据ASIN或取商品
export function getAsinByAsins(data) {
    return request({
        url:'/api/ad/groups/getAsinByAsins',
        method:'post',
        data
    });
}

export function listByGroupId(id,profileId) {
    return request({
        url:'/api/ad/product/listByGroupId',
        method:'get',
        params:{
            groupsId:id
        },
        headers:{
            'profileId':profileId
        }
    });
}


// 新增
export function product(data,profileId){
    return request({
        url:'/api/ad/product',
        method:'post',
        data,
        headers:{
            "profileId":profileId
        }
    });
}


export function getKeywordsByAsin(data,profileId) {
    return request({
        url:`/api/ad/keyword/listSuggestedKeywordsByAsin/${data}`,
        method:'get',
        headers:{
            "profileId":profileId
        }
    });
}


// 根据ASIN获取建议分类投放
export function getCategories(params,profileId) {
    return request({
        url:`/api/ad/target/getCategories/${params}`,
        method:'get',
        headers:{
            "profileId":profileId
        }
    });
}
// 获取已建议
export function getTargetProduct(params,profileId) {
    return request({
        url:`/api/ad/target/getTargetProductRecommendations/${params}`,
        method:'get',
        headers:{
            "profileId":profileId
        }
    });
}

// 搜索获取品牌

export function getTargetsBrands(params,profileId) {
    return request({
        url:'/api/ad/target/getTargetsBrands',
        method:'get',
        params,
        headers:{
            "profileId":profileId
        }
    });
}


// 添加否定关键词
export function addNegativeKeyword(data,profileId) {
    return request({
        url:'/api/ad/keyword/addNegativeKeyword',
        method:'post',
        data,
        headers:{
            "profileId":profileId
        }
    });
}


export function listNegativeTarget(params) {
    return request({
        url:'/api/ad/target/listNegativeTarget',
        method:'get',
        params
    });
}
export function listNegativeKeywords(params) {
    return request({
        url:'/api/ad/keyword/listNegativeKeywords',
        method:'get',
        params
    });
}

// 修改否定关键词状态
export function updateNeKeyword(data,profileId) {
    return request({
        url:'/api/ad/keyword/updateNeKeyword',
        method:'put',
        data,
        headers:{
            "profileId":profileId
        }
    });
}

// 添加否定商品
export function addNeTargets(data,profileId) {
    return request({
        url:'/api/ad/target/addNeTargets',
        method:'post',
        data,
        headers:{
            "profileId":profileId
        }
    });
}

// 修改否定投放状态 否定商品归档
export function archivedNeTarget(data,profileId) {
    return request({
        url:'/api/ad/target/archivedNeTarget',
        method:'put',
        data,
        headers:{
            "profileId":profileId
        }
    });
}

// 新增关键字
export function addKeyword(data,profileId) {
    return request({
        url:'/api/ad/keyword/addKeyword',
        method:'post',
        data,
        headers:{
            "profileId":profileId
        }
    });
}


// 查询已存在关键词

export function listExistKeywords(params) {
    return request({
         url:'/api/ad/keyword/listExistKeywords',
         method:'get',
         params
    });
}

// 新增商品投放
export function addTargets(data,profileId) {
    return request({
        url:'/api/ad/target/addTargets',
        method:'post',
        data,
        headers:{
            "profileId":profileId
        }
    });
}

// 查询已存在投放
export function listExistTarget(params) {
    return request({
        url:'/api/ad/target/listExistTarget',
        method:'get',
        params,
    });
}

// 细化初始化
export function groupDetailInit(params,profileId) {
    return request({
        url:'/api/ad/groups/groupDetailInit',
        method:'get',
        params,
        headers:{
            "profileId":profileId
        }
    });
}

export function listCampaignNegativeKeywords(params) {
    return request({
        url:'/api/ad/keyword/listCampaignNegativeKeywords',
        method:'get',
        params
    });
}

// 查询商品初始化
export function goodsPageInit() {
    return request({
        method:'get',
        url:'/api/amazon/asin/pageInit',
    });
}

// 表现明细查询
export function listByProduct(params) {
   return request({
     url: "/api/ad/product/listByProduct",
     params,
     method: "get"
   })
}
